'use client'

import { AnimatePresence, motion, useMotionTemplate } from 'framer-motion'
import React from 'react'
import useProgress from '~/lib/use-progress'

export const ProgressBarContext = React.createContext<ReturnType<typeof useProgress> | null>(null)

export function useProgressBar() {
  const progress = React.useContext(ProgressBarContext)

  if (progress === null) {
    throw new Error('Need to be inside provider')
  }

  return progress
}

export function ProgressBar({ children }: { children: React.ReactNode }) {
  const progress = useProgress()
  const width = useMotionTemplate`${progress.value}%`

  return (
    <ProgressBarContext.Provider value={progress}>
      <AnimatePresence onExitComplete={progress.reset}>
        {progress.state !== 'complete' && (
          <motion.div
            style={{ width }}
            exit={{ opacity: 0 }}
            className='absolute top-0 h-1 bg-sky-500 shadow-lg shadow-sky-500/20'
          />
        )}
      </AnimatePresence>

      {children}
    </ProgressBarContext.Provider>
  )
}
